<template>
  <validation-observer ref="simpleRules">
    <div>
      <div class="card">
        <div class="section-title pl-2 pt-2 pr-2">
          <span class="font-weight-bolder">รายงาน</span>
        </div>
        <!-- <router-link
          :to="{name: 'admin-paymenttochina-create'}"
          class="px-2"
        > -->
        <div class="px-2">
          <button
            type="button"
            :class="`anan-button--xl-large anan-button ${items.length === 0 ? '': 'anan-button--primary'} anan-button--normal`"
            :disabled="items.length === 0"
            @click="DowmloadExcels"
          >
            <span>Dowmload Excel</span>
          </button>

          <button
            v-if="selected !== 23"
            type="button"
            class="anan-button--xl-large anan-button anan-button--normal text-danger"
            @click="dateStart = null, dateEnd = null, selectFields(),GetOrder()"
          >
            <span>ล้างวันที่</span>
          </button>
        </div>

        <!-- </router-link> -->
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="d-flex pl-2 pt-2 pr-2">
            <b-form-select
              v-model="selected"
              :options="options"
              class="px-1 w-25 mr-2 custom-select-sm"

              @change="dateStart = null, dateEnd = null, selectFields(), GetOrder()"
            />

            <v-select
              v-if="selected === 22"
              id="h-aminCode"
              v-model="admin_name"
              label="username"
              :options="adminCode"
              class="px-1 w-50 mr-2 select-size-sm select-custom-size"
              @input="selectFields(), GetOrder()"
            />
            <b-form-select
              v-if="selected === 22"
              v-model="selectedadmin"
              :options="optionsadmin"
              class="px-1 w-25 mr-2 custom-select-sm"
              @change="selectFields(), GetOrder()"
            />
            <div
              v-if="selected !== 23"
              class="w-25 mr-2"
              @input="selectFields(),GetOrder()"
            >
              <flat-pickr
                v-model="dateStart"
                class="form-control custom-select-sm"
                placeholder="Start date"
              />
            </div>
            <div
              v-if="selected !== 23"
              class="w-25 mr-2"
              @input="selectFields(),GetOrder()"
            >
              <flat-pickr
                v-model="dateEnd"
                class="form-control custom-select-sm"
                placeholder="End date"
              />
            </div>
            <!-- @change="selectFields()" -->
            <div class="w-100 " />
          </div>
          <div
            v-if="selected === 1"
            class="pl-2 pr-2 pt-1"
          >
            <b-table
              striped
              hover
              responsive
              :items="items"
              :fields="fields"
              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <template #cell(created_at)="data">
                {{ time(data.item.created_at) }}
              </template>
              <template #cell(amount)="data">
                <span
                  v-if="data.item.amount < 0"
                  class="text-danger"
                >
                  {{ Commas(data.item.amount) }} ฿
                </span>
                <span
                  v-else
                  :style="getAmountStyle(data.item.type,data.item.type_amount)"
                >
                  {{ getAmountPrefix(data.item.type,data.item.type_amount) }} {{ Commas(data.item.amount) }} ฿
                </span>
              </template>

              <template #cell(approvalDate)="data">
                <img
                  :src="`/bankIcon/${data.item.path_bank}`"
                  height="30"
                  class="mr-50 rounded-sm"
                >
              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <div class="mr-4">
                <h3>รวม {{ Commas(total_shops) }} รายการ</h3>
              </div>
              <div
                class="mr-2"
              >
                <h3 :class="`${Number(total_products) < 0 ? 'text-danger': 'text-success'}`">
                  {{ Number(total_products) < 0 ? `-${Commas(total_products)}` :Commas(total_products) }} ฿
                </h3>
              </div>
            </div>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
          <div
            v-if="selected === 2 || selected === 4 || selected === 5 || selected === 6 || selected === 8
              || selected === 11 || selected === 12 || selected === 13 || selected === 15 || selected === 16 || selected === 17 || selected === 20 || selected === 21 || selected === 30"
            class="pl-2 pr-2 pt-1"
          >
            <b-table
              striped
              hover
              responsive

              :items="items"
              :fields="fields1"

              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>
              <template #cell(created_at)="data">
                {{ time(data.item.created_at) }}
              </template>
              <template #cell(amount)="data">
                <span
                  v-if="data.item.amount < 0"
                  class="text-danger"
                >
                  {{ Commas(data.item.amount) }} ฿
                </span>
                <span
                  v-else
                  :style="getAmountStyle(data.item.type,data.item.type_amount)"
                >
                  {{ getAmountPrefix(data.item.type, data.item.type_amount) }} {{ Commas(data.item.amount) }} ฿
                </span>
              </template>
              <!-- <template #cell(number)="data">
              <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
                RPT-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
              </b-link>
            </template> -->

              <template #cell(detail)="data">
                <b-link
                  v-if="selected === 2"
                  :to="{name: 'admin-finance-withdraw-view', params: {id: data.item.ref_id}}"
                  target="_blank"
                >
                  {{ data.item.detail }}
                </b-link>
                <b-link
                  v-else-if="selected === 4"
                  :to="{name: 'admin-order-detail', params: {id: data.item.ref_id}}"
                  target="_blank"
                >
                  {{ data.item.detail }}
                </b-link>
                <b-link
                  v-else-if="selected === 5"
                  :to="{name: 'admin-export_edit', params: {id: data.item.ref_id}}"
                  target="_blank"
                >
                  {{ data.item.detail }}
                </b-link>
                <b-link
                  v-else-if="selected === 11"
                  :to="{name: 'admin-lost-package-edit', params: {id: data.item.ref_id}}"
                  target="_blank"
                >
                  {{ data.item.detail }}
                </b-link>
                <b-link
                  v-else-if="selected === 12"
                  :to="{name: 'admin-china-payfor-refund-edit', params: {id: data.item.ref_id}}"
                  target="_blank"
                >
                  {{ data.item.detail }}
                </b-link>
                <b-link
                  v-else-if="selected === 16"
                  :to="{name: 'admin-point-edit', params: {id: data.item.ref_id}}"
                  target="_blank"
                >
                  {{ data.item.detail }}
                </b-link>
                <span v-else>{{ data.item.detail }}</span>
              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <div class="mr-4">
                <h3>รวม {{ Commas(total_shops) }} รายการ</h3>
              </div>
              <div
                v-if="selected === 2 || selected === 5 || selected === 6 || selected === 8 || selected === 11|| selected === 12 || selected === 15 || selected === 16 || selected === 21 || selected === 13"
                class="mr-2"
              >
                <h3 :class="`${Number(total_products) < 0 ? 'text-danger': 'text-success'}`">
                  {{ Commas(total_products) }} ฿
                </h3>
              </div>
              <div
                v-else
                class="mr-2"
              >
                <h3 :class="`${Number(total_products) < 0 ? 'text-success': 'text-danger'}`">
                  {{ Number(total_products) < 0 ? Commas(total_products) : `-${Commas(total_products)}` }} ฿
                </h3>
              </div>
            </div>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
          <div
            v-if="selected === 3"
            class="pl-2 pr-2 pt-1"
          >
            <b-table
              striped
              hover
              responsive

              :items="items"
              :fields="fields2"

              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <template #cell(created_at)="data">
                {{ time(data.item.created_at) }}
              </template>
              <template #cell(payment_charge_per_save)="data">
                {{ Commas(data.item.payment_charge_per_save) }} ฿
              </template>
              <template #cell(payment_service_fee)="data">
                {{ Commas(data.item.payment_service_fee) }} ฿
              </template>
              <template #cell(payment_shipping_cost)="data">
                {{ Commas(data.item.payment_shipping_cost) }} ฿
              </template>
              <template #cell(amount)="data">
                <span
                  v-if="data.item.amount < 0"
                  class="text-danger"
                >
                  {{ Commas(data.item.amount) }} ฿
                </span>
                <span
                  v-else
                  :style="getAmountStyle(data.item.type,data.item.type_amount)"
                >
                  {{ getAmountPrefix(data.item.type,data.item.type_amount) }} {{ Commas(data.item.amount) }} ฿
                </span>
              </template>
              <template #cell(detail)="data">
                <b-link
                  :to="{name: 'shipment_payment_edit', params: {id: data.item.ref_id}}"
                  target="_blank"
                >
                  {{ data.item.detail }}
                </b-link>
              </template>
              <template #cell(actions)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="EditType(data.item)"
                />
              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <div class="mr-4">
                <h3>รวม {{ Commas(total_shops) }} รายการ</h3>
              </div>
              <div
                class="mr-2"
              >
                <h3 :class="`${Number(total_products) < 0 ? 'text-success': 'text-danger'}`">
                  {{ Number(total_products) < 0 ? Commas(total_products) : `-${Commas(total_products)}` }} ฿
                </h3>
              </div>
            </div>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
          <div
            v-if="selected === 7"
            class="pl-2 pr-2 pt-1"
          >
            <b-table
              striped
              hover
              responsive

              :items="items"
              :fields="fields3"

              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <template #cell(created_at)="data">
                {{ time(data.item.created_at) }}
              </template>
              <template #cell(service_amount)="data">
                {{ Commas(data.item.service_amount) }} ฿
              </template>
              <template #cell(total_amount)="data">
                ¥ {{ Commas(data.item.total_amount) }}
              </template>
              <template #cell(rate)="data">
                {{ Commas(data.item.rate) }} ฿
              </template>
              <template #cell(amount)="data">
                <span
                  v-if="data.item.amount < 0"
                  class="text-danger"
                >
                  {{ Commas(data.item.amount) }} ฿
                </span>
                <span
                  v-else
                  :style="getAmountStyle(data.item.type,data.item.type_amount)"
                >
                  {{ getAmountPrefix(data.item.type,data.item.type_amount) }} {{ Commas(data.item.amount) }} ฿
                </span>
              </template>
              <template #cell(detail)="data">
                <b-link
                  :to="{name: 'admin-china-payfor-check', params: {id: data.item.ref_id}}"
                  target="_blank"
                >
                  {{ data.item.detail }}
                </b-link>
              </template>

              <template #cell(actions)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="EditType(data.item)"
                />
              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <div class="mr-4">
                <h3>รวม {{ Commas(total_shops) }} รายการ</h3>
              </div>
              <div
                class="mr-2"
              >
                <h3 :class="`${Number(total_products) < 0 ? 'text-success': 'text-danger'}`">
                  {{ Number(total_products) < 0 ? Commas(total_products) : `-${Commas(total_products)}` }} ฿
                </h3>
              </div>
            </div>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
          <div
            v-if="selected === 9"
            class="pl-2 pr-2 pt-1"
          >
            <b-table
              striped
              hover
              responsive

              :items="items"
              :fields="fields4"

              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>
              <template #cell(amount)="data">
                <span
                  v-if="data.item.amount < 0"
                  class="text-danger"
                >
                  {{ Commas(data.item.amount) }} ฿
                </span>
                <span
                  v-else
                  :style="getAmountStyle(data.item.type,data.item.type_amount)"
                >
                  {{ getAmountPrefix(data.item.type,data.item.type_amount) }} {{ Commas(data.item.amount) }} ฿
                </span>
              </template>
              <template #cell(total_amount)="data">
                ¥ {{ Commas(data.item.total_amount) }}
              </template>
              <template #cell(created_at)="data">
                {{ time(data.item.created_at) }}
              </template>
              <template #cell(rate)="data">
                {{ Commas(data.item.rate) }} ฿
              </template>
              <template #cell(detail)="data">
                <b-link
                  :to="{name: 'admin-china-alipay-check', params: {id: data.item.ref_id}}"
                  target="_blank"
                >
                  {{ data.item.detail }}
                </b-link>
              </template>

              <template #cell(actions)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="EditType(data.item)"
                />
              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <div class="mr-4">
                <h3>รวม {{ Commas(total_shops) }} รายการ</h3>
              </div>
              <div
                class="mr-2"
              >
                <h3 :class="`${Number(total_products) < 0 ? 'text-danger': 'text-success'}`">
                  {{ Number(total_products) < 0 ? Commas(total_products) : `-${Commas(total_products)}` }} ฿
                </h3>
              </div>
            </div>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
          <div
            v-if="selected === 10"
            class="pl-2 pr-2 pt-1"
          >
            <b-table
              striped
              hover
              responsive

              :items="items"
              :fields="fields6"

              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <template #cell(detail)="data">
                <b-link
                  :to="{name: 'admin-transfer-check', params: {id: data.item.ref_id}}"
                  target="_blank"
                >
                  {{ data.item.detail }}
                </b-link>
              </template>
              <template #cell(total_amount)="data">
                <!-- <b-link :to="{name: 'admin-transfer-check', params: {id: data.item.ref_id}}"> -->
                ¥ {{ Commas(data.item.total_amount) }}
                <!-- </b-link> -->
              </template>
              <template #cell(rate)="data">
                <!-- <b-link :to="{name: 'admin-transfer-check', params: {id: data.item.ref_id}}"> -->
                {{ Commas(data.item.rate) }} ฿
                <!-- </b-link> -->
              </template>
              <template #cell(created_at)="data">
                <!-- <b-link :to="{name: 'admin-transfer-check', params: {id: data.item.ref_id}}"> -->
                {{ time(data.item.created_at) }}
                <!-- </b-link> -->
              </template>
              <template #cell(amount)="data">
                <span
                  v-if="data.item.amount < 0"
                  class="text-danger"
                >
                  {{ Commas(data.item.amount) }} ฿
                </span>
                <span
                  v-else
                  :style="getAmountStyle(data.item.type,data.item.type_amount)"
                >
                  {{ getAmountPrefix(data.item.type,data.item.type_amount) }} {{ Commas(data.item.amount) }} ฿
                </span>
              </template>

              <template #cell(status)="data">
                <span
                  v-if="data.item.status === 0"
                  class="badge badge-danger badge-pill"
                > {{ $t('cancelled') }}
                </span>
                <span
                  v-if="data.item.status === 1"
                  class="badge badge-primary badge-pill"
                > {{ $t('pendingReview') }}
                </span>
                <span
                  v-if="data.item.status === 2"
                  class="badge badge-success badge-pill"
                > {{ $t('operationCompleted') }}
                </span>

              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <div class="mr-4">
                <h3>รวม {{ Commas(total_shops) }} รายการ</h3>
              </div>
              <div
                class="mr-2"
              >
                <h3 :class="`${Number(total_products) < 0 ? 'text-success': 'text-danger'}`">
                  {{ Number(total_products) < 0 ? Commas(total_products) : `-${Commas(total_products)}` }} ฿
                </h3>
              </div>
            </div>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
          <div
            v-if="selected === 22"
            class="pl-2 pr-2 pt-1"
          >
            <b-table
              striped
              hover
              responsive

              :items="items"
              :fields="fields7"

              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <!-- <template #cell(number)="data">
              <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
                RPT-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
              </b-link>
            </template> -->
              <template #cell(created_at)="data">
                {{ time(data.item.created_at) }}
              </template>
              <template #cell(itemnumber_text)="data">
                ORD-{{ data.item.orderId_text }}
              </template>
              <template #cell(username)="data">
                {{ selectedadmin === 1 ? data.item.lock_by: data.item.username }}
                <!-- ORD-{{ data.item.lock_by }} -->
              </template>
              <template #cell(amountshop)="data">
                {{ data.item.shops.length }}
              </template>
              <template #cell(amountlink)="data">
                {{ data.item.shops.reduce((total, shop) => total + shop.products.length, 0) }}
              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <div class="mr-4">
                <h3>รวม {{ Commas(total_shops) }} รายการ</h3>
              </div>
              <div class="mr-2">
                <h3>{{ Commas(total_products) }}</h3>
              </div>
            </div>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
          <div
            v-if="selected === 23"
            class="pl-2 pr-2 pt-1"
          >
            <b-table
              striped
              hover
              responsive
              :items="items"
              :fields="fields5"
              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <template #cell(balance)="data">
                {{ Commas(data.item.balance) }}
              </template>

              <template #cell(actions)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="EditType(data.item)"
                />
              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <div class="mr-4">
                <h3>รวม {{ Commas(total_shops) }} รายการ</h3>
              </div>
              <div class="mr-2">
                <h3>{{ Commas(total_products) }}</h3>
              </div>
            </div>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
          <div
            v-if="selected === 25"
            class="pl-2 pr-2 pt-1"
          >
            <b-table
              striped
              hover
              responsive
              :items="items"
              :fields="fields8"
              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>
              <template #cell(created_at)="data">
                {{ time(data.item.created_at) }}
              </template>
              <template #cell(paymentnew)="data">
                {{ Commas(data.item.payment - data.item.Service_fee - data.item.Shipping_cost) }} ฿
              </template>
              <template #cell(Service_fee)="data">
                {{ Commas(data.item.Service_fee) }} ฿
              </template>
              <template #cell(Shipping_cost)="data">
                {{ Commas(data.item.Shipping_cost) }} ฿
              </template>
              <template #cell(payment)="data">
                {{ Commas(data.item.payment) }} ฿
              </template>
              <template #cell(statusbill)="data">
                <span
                  v-if="data.item.statusbill === 1"
                  class="badge badge-success badge-pill"
                > {{ $t('operationCompleted') }}
                </span>
                <span
                  v-else
                  class="badge badge-primary badge-pill"
                > {{ $t('pendingReview') }}
                </span>
              </template>

              <template #cell(actions)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="EditType(data.item)"
                />
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>

          <div
            v-if="selected === 26 || selected === 27 || selected === 28"
            class="pl-2 pr-2 pt-1"
          >
            <b-table
              striped
              hover
              responsive
              :items="items"
              :fields="fields9"
              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>
              <template #cell(amount)="data">
                {{ Commas(data.item.count) }}
              </template>
              <template #cell(total_amount_tracking)="data">
                {{ Commas(data.item.total_amount_tracking) }}
              </template>
              <template #cell(total_amount_kilo)="data">
                {{ Commas(data.item.total_amount_kilo) }}
              </template>
              <template #cell(total_amount_cue)="data">
                {{ CommasCue(data.item.total_amount_cue) }}
              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <div class="mr-4">
                <h3>รวม {{ Commas(total_shops) }} รายการ</h3>
              </div>
            </div>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="usertotal"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>

          <div
            v-if="selected === 29"
            class="pl-2 pr-2 pt-1"
          >
            <b-table
              striped
              hover
              responsive
              :items="items"
              :fields="fields10"
              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>
              <template #cell(created_at)="data">
                {{ time(data.item.created_at) }}
              </template>
              <template #cell(rate_preorder)="data">
                {{ Commas(data.item.rate_preorder_b) }}/{{ Commas(data.item.rate_preorder) }}
              </template>
              <template #cell(rate_import)="data">
                  {{ Commas(data.item.rate_import_b) }}/{{ Commas(data.item.rate_import) }}
              </template>
              <template #cell(rate_payment)="data">
                {{ CommasCue(data.item.rate_payment_b) }}/{{ CommasCue(data.item.rate_payment) }}
              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-overlay>
      </div>

    </div>
  </validation-observer>
</template>

<script>
import {
  BTable,
  BLink,
  BFormSelect,
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moment from 'moment-timezone'

export default {
  components: {
    vSelect,
    BTable,
    BLink,
    BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    flatPickr,
    BOverlay,

  },
  data() {
    return {
      isActive: 'all',
      showOver: false,
      required,
      selected: 1,
      amount: 0,
      detail: '',
      type: null,
      customerCode: '',
      prefix: '',
      prefixNum: '',
      adminCode: [],
      admin_name: '',
      // options: [
      //   { value: null, text: 'ทั้งหมด' },
      //   { value: 1, text: 'รถ' },
      //   { value: 2, text: 'เรือ' },
      // ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      fields: [
        {
          key: 'itemnumber_text',
          label: 'หมายเลขรายการ',
          thStyle: { width: '15%' },
          thClass: 'text-center',
        },
        {
          key: 'user_id',
          label: 'User ID',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'วันที่ทำรายการ',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'username_cus',
          label: 'รหัสลูกค้า',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'approvalDate',
          label: 'ธนาคาร',
          sortable: false,
          thStyle: { width: '5%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'detail',
          label: 'รายละเอียด',
          sortable: false,
          thStyle: { width: '30%' },
          thClass: 'text-center',
        },
        {
          key: 'amount',
          label: 'จำนวน',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      fields1: [
        {
          key: 'itemnumber_text',
          label: 'หมายเลขรายการ',
          thStyle: { width: '15%' },
          thClass: 'text-center',
        },
        {
          key: 'user_id',
          label: 'User ID',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'วันที่ทำรายการ',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'username_cus',
          label: 'รหัสลูกค้า',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'detail',
          label: 'รายละเอียด',
          sortable: false,
          thStyle: { width: '30%' },
          thClass: 'text-center',
        },
        {
          key: 'amount',
          label: 'จำนวน',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      fields2: [
        {
          key: 'itemnumber_text',
          label: 'หมายเลขรายการ',
          thStyle: { width: '10%' },
          thClass: 'text-center',
        },
        {
          key: 'user_id',
          label: 'User ID',
          sortable: false,
          thStyle: { width: '5%' },
          thClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'วันที่ทำรายการ',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'username_cus',
          label: 'รหัสลูกค้า',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'payment_charge_per_save',
          label: 'ค่าขนส่งสินค้า',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'payment_service_fee',
          label: 'ค่าบริการโกดังสินค้า',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'payment_shipping_cost',
          label: 'ค่าจัดส่งในไทย',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'payment_delivery_name',
          label: 'ประเภทการจัดส่ง',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'detail',
          label: 'รายละเอียด',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
        },
        {
          key: 'amount',
          label: 'จำนวน',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      fields3: [
        {
          key: 'itemnumber_text',
          label: 'หมายเลขรายการ',
          thStyle: { width: '10%' },
          thClass: 'text-center',
        },
        {
          key: 'user_id',
          label: 'User ID',
          sortable: false,
          thStyle: { width: '5%' },
          thClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'วันที่ทำรายการ',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'username_cus',
          label: 'รหัสลูกค้า',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'service_amount',
          label: 'ค่าบริการ',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'total_amount',
          label: 'รวม',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'rate',
          label: 'เรทราคา',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'detail',
          label: 'รายละเอียด',
          sortable: false,
          thStyle: { width: '20%' },
          thClass: 'text-center',
        },
        {
          key: 'amount',
          label: 'จำนวน',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      fields4: [
        {
          key: 'itemnumber_text',
          label: 'หมายเลขรายการ',
          thStyle: { width: '10%' },
          thClass: 'text-center',
        },
        {
          key: 'user_id',
          label: 'User ID',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'วันที่ทำรายการ',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'username_cus',
          label: 'รหัสลูกค้า',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'total_amount',
          label: 'รวม',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'rate',
          label: 'เรทราคา',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'detail',
          label: 'รายละเอียด',
          sortable: false,
          thStyle: { width: '25%' },
          thClass: 'text-center',
        },
        {
          key: 'amount',
          label: 'จำนวน',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      fields5: [
        {
          key: 'username',
          label: 'รหัสลูกค้า',
          sortable: false,
          thStyle: { width: '50%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'balance',
          label: 'จำนวน',
          sortable: false,
          thStyle: { width: '50%' },
          thClass: 'text-center',
          tdClass: 'text-right',
        },
      ],
      fields6: [
        {
          key: 'itemnumber_text',
          label: 'หมายเลขรายการ',
          thStyle: { width: '10%' },
          thClass: 'text-center',
        },
        {
          key: 'user_id',
          label: 'User ID',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'วันที่ทำรายการ',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'username_cus',
          label: 'รหัสลูกค้า',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'total_amount',
          label: 'รวม',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'status',
          label: 'สถานะ',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'rate',
          label: 'เรทราคา',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'detail',
          label: 'รายละเอียด',
          sortable: false,
          thStyle: { width: '20%' },
          thClass: 'text-center',
        },
        {
          key: 'amount',
          label: 'จำนวน',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      fields7: [
        {
          key: 'itemnumber_text',
          label: 'หมายเลขรายการ',
          thStyle: { width: '15%' },
          thClass: 'text-center',
        },
        {
          key: 'user_id',
          label: 'User ID',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'วันที่ทำรายการ',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'username',
          label: 'รหัสลูกค้า',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'amountshop',
          label: 'จำนวนร้าน',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'amountlink',
          label: 'จำนวนลิ้งค์',
          sortable: false,
          thStyle: { width: '5%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      fields8: [
        {
          key: 'billnumber',
          label: 'หมายเลขรายการ',
          thStyle: { width: '10%' },
          thClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'วันที่ทำรายการ',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'itemnumber_text',
          label: 'เลขที่ใบส่งของ',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'username',
          label: 'รหัสลูกค้า',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'texfullname',
          label: 'นาม',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'paymentnew',
          label: 'T-0001',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'Service_fee',
          label: 'T-0002',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'Shipping_cost',
          label: 'T-0003',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'payment',
          label: 'จำนวน',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'statusbill',
          label: 'สถานะ',
          sortable: false,
          thStyle: { width: '5%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },

      ],
      fields9: [
        {
          key: 'itemnumber_text',
          label: 'รหัสพนักงาน',
          thStyle: { width: '15%' },
          thClass: 'text-center',
        },
        {
          key: 'username',
          label: 'ชื่อพนักงาน',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
        },
        {
          key: 'amount',
          label: 'จำนวนบิลที่ทำงาน',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'total_amount_tracking',
          label: 'จำนวนแทรค',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'total_amount_cue',
          label: 'จำนวนคิว',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'total_amount_kilo',
          label: 'จำนวนกิโล',
          sortable: false,
          thStyle: { width: '5%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      fields10: [
        {
          key: 'created_at',
          label: 'วันที่ทำรายการ',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'rate_preorder',
          label: 'เรทพรีออเดอร์',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'rate_import',
          label: 'เรทฝากชำระค่าสินค้า',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'rate_payment',
          label: 'โอนเงินธนาคาร/Alipay',
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
          {
          key: 'add_by',
          label: 'แก้ไขโดย',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      items: [],
      dateStart: null,
      dateEnd: null,
      selectedadmin: 1,
      total_shops: 0,
      total_products: 0,
      useritem: [],
      usertotal: 0,
    }
  },
  computed: {
    options() {
      return [
        { value: 1, text: this.$t('topUp') },
        { value: 2, text: this.$t('withdraw') },
        { value: 3, text: this.$t('key-287') },
        { value: 4, text: this.$t('key-179') },
        { value: 5, text: this.$t('key-180') },
        { value: 6, text: this.$t('mall') },
        { value: 7, text: this.$t('DepositTaobao') },
        { value: 8, text: this.$t('commissionFee') },
        { value: 9, text: this.$t('topupAlipay') },
        { value: 10, text: this.$t('transferToChina') },
        { value: 11, text: this.$t('reportMissing') },
        { value: 12, text: this.$t('key-288') },
        { value: 13, text: this.$t('domesticDeliveryFee') },
        { value: 15, text: this.$t('sendToChina') },
        { value: 16, text: this.$t('redeemPoints') },
        // { value: 17, text: this.$t("Refunds") },
        // { value: 20, text: "ยกเลิก Commission" },
        { value: 21, text: this.$t('other') },
        { value: 29, text: 'รายละเอียดการตั้งค่าเรท' },
        { value: 30, text: 'ค่าคูปอง' },
        { value: 25, text: 'สรุปใบเสร็จ' },
        { value: 23, text: 'ยอดเงินในระบบของลูกค้า' },
        { value: 24, text: 'พิมพ์รายชื่อลูกค้า' },
        { value: 22, text: 'สรุปการทำงาน' },
        { value: 28, text: 'สรุปการทำงาน - เช็คของ' },
        { value: 26, text: 'สรุปการทำงาน - หาของ' },
        { value: 27, text: 'สรุปการทำงาน - แพ็คของ' },
      ]
    },
    optionsadmin() {
      return [
        { value: 1, text: 'ผู้สรุปยอด' },
        { value: 2, text: 'ผู้กดสั่งซื้อ' },
      ]
    },
  },
  async  mounted() {
    await this.getData()
    this.TotalData()
    this.GetUserAdmin()
    // this.GetEmployee()
  },
  methods: {
    time(data) {
      return moment(data).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
    },
    selectFields() {
      if (this.selected === 24) {
        this.DowmloadExcels()
        this.selected = 1
      }
      this.getData()
    },
    getAmountStyle(amount,typeAmount) {
      if (amount === 21) {
          if (typeAmount) {
            return { color: 'green' }
          } else {
            return { color: 'red' }
          }
        } else {
            if ([1, 8, 11, 12, 17, 16].includes(amount)) {
              return { color: 'green' }
            }
            if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20, 22].includes(amount)) {
              return { color: 'red' }
            }
        }
    },
    getAmountPrefix(value, typeAmount) {
        if (value === 21) {
          if (typeAmount) {
            return '+'
          } else {
            return '-'
          }
        } else {
            if ([1, 8, 11, 12, 17, 16 ].includes(value)) {
              return '+'
            }
            if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20, 22].includes(value)) {
                return '-'
            }
        }
    },
    async GetUserAdmin() {
      try {
        const { data: res } = await this.$http.get('/users/admin/active')
        this.adminCode = res
        // console.debug(res)
      } catch (err) {
        console.log(err)
      }
    },
    async GetEmployee() {
      try {
        const params = {
          perPage: this.perPage,
          page: this.currentPage,
        }
        const { data: res } = await this.$http.get('/users/GetEmployee', { params })
        // if (this.selected === 26 || this.selected === 27) {
        this.usertotal = res.total
        // }
        this.useritem = res.data
      } catch (err) {
        console.log(err)
      }
      // users/GetEmployee
    },
    async getData() {
      this.showOver = true
      try {
        const params = {
          perPage: this.perPage,
          page: this.currentPage,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          type: this.selected,
          admin_name: this.admin_name ? this.admin_name.username : null,
          typeadmin: this.selectedadmin,
          ID: [],
        }
        if (this.selected === 26 || this.selected === 27 || this.selected === 28) {
          // params.ID = this.useritem.map(item => item.id)
          const { data: res } = await this.$http.get('/transactions/SearchAndPack', { params })
          this.items = res.data
          this.totalRows = res.total
          this.total_shops = res.total
          this.showOver = false
        } else if (this.selected === 29) {
          const { data: res } = await this.$http.get('/transactions/GetRateData', { params })
          this.items = res.data
          this.totalRows = res.total
            this.showOver = false
         } else{
          const { data: res } = await this.$http.get('/transactions/show/report', { params })
          this.items = res.data
          this.totalRows = res.total
          if (this.selected === 23) {
            this.total_shops = res.total
          }
          this.showOver = false
        }
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    DowmloadExcels() {
      // this.selected
      this.showOver = true
      const params = {
        perPage: this.perPage,
        page: this.currentPage,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        type: this.selected,
        admin_name: this.admin_name ? this.admin_name.username : null,
        typeadmin: this.selectedadmin,
      }
      this.$http.post('/print/report', params, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          const namedownload = moment().tz('Asia/Bangkok').format('HH:mm:ss')
          link.download = `report-${namedownload}`
          link.click()
          this.showOver = false
        })
        .catch(error => {
          this.SwalError(error)
          this.showOver = false
        })
    },
    async TotalData() {
      try {
        const params = {
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          type: this.selected,
        }
        const { data: res } = await this.$http.get('/transactions/ShowREF', { params })
        this.total_shops = res.totalShops
        this.total_products = res.total_products
      } catch (err) {
        console.log(err)
      }
    },
    async GetOrder() {
      try {
        if (this.selected === 2 || this.selected === 3 || this.selected === 4 || this.selected === 5 || this.selected === 6 || this.selected === 7
          || this.selected === 9 || this.selected === 10 || this.selected === 11 || this.selected === 12 || this.selected === 15 || this.selected === 16 || this.selected === 21 || this.selected === 13 || this.selected === 30
        ) {
          const params = {
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            type: this.selected,
          }
          const { data: res } = await this.$http.get('/transactions/show/WCASH', { params })
          this.total_shops = res.totalShops
          if (this.selected === 3 || this.selected === 4 || this.selected === 7 || this.selected === 11 || this.selected === 12 || this.selected === 16|| this.selected === 30 ) {
            this.total_products = res.amount - res.total_products
          } else if (this.selected === 21) {
            this.total_products = res.amount
          } else {
            this.total_products = res.total_products - res.amount
          }
        } else if (this.selected === 1) {
          this.TotalData()
        } else if (this.selected === 8) {
          const params = {
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            type: this.selected,
          }
          const { data: res } = await this.$http.get('/transactions/ShowCommission', { params })
          console.debug(res)
          this.total_shops = res.totalShops
          // if (this.selected === 4) {
          //   this.total_products = res.amount - res.total_products
          // } else {
          this.total_products = res.amount - res.total_products
          // }
          //
        } else if (this.selected === 22) {
          const params = {
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            type: this.selected,
            admin_name: this.admin_name ? this.admin_name.username : null,
            typeadmin: this.selectedadmin,
          }
          const { data: res } = await this.$http.get('/transactions/show/order', { params })
          this.total_shops = res.totalShops
          this.total_products = res.total_products
        } else if (this.selected === 23) {
          const { data: res } = await this.$http.get('/transactions/show/TotalBalance')
          this.total_products = res.total.total_balance
        }else {
          this.total_shops = 0
          this.total_products = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
    EditType(data) {
      this.$router.push({
        name: 'admin-user-type-edit',
        params: { id: data.id },
      })
    },
    DeleteData(data) {
      this.$bvModal
        .msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
          title: 'ยืนยันการลบข้อมูล',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const params = {
              id: data.id,
            }
            this.$http.post('groupbyuser/list/delete', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>

</style>
